<template>
  <div class="env-container" ref="3d-container">
    <p v-if="percentLoaded < 100">Loading...</p>
    {{ percentLoaded }}%
  </div>
</template>

<script>
import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  AmbientLight,
  DirectionalLight,
  Vector3
} from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
const scene = new Scene()
const width = 500
const height = 500
const camera = new PerspectiveCamera(75, width / height, 0.1, 1000)
const light = new AmbientLight(0xffffff)
const directionalLight = new DirectionalLight(0xffffff, 0.5)
console.log('Light pos: ', directionalLight.position)
const renderer = new WebGLRenderer({ alpha: true, antialias: true })
const loader = new GLTFLoader()

let controls

renderer.setSize(width, height)
renderer.setClearColor(0xffffff, 0)

export default {
  data () {
    return {
      percentLoaded: 0,
      models: ['terp-arms-legs.gltf', 'terp-body.gltf', 'terp-eyes-mouth.gltf']
    }
  },
  mounted () {
    const renderElement = this.$refs['3d-container']
    renderElement.appendChild(renderer.domElement)

    scene.add(light)
    directionalLight.position.set(1, 1, 1)
    scene.add(directionalLight)
    camera.position.z = 6
    camera.position.y = 0

    controls = new OrbitControls(camera, renderElement)
    controls.autoRotate = true
    controls.target = new Vector3(0, 0, 0)
    controls.update()

    this.models.forEach((model) => {
      this.loadModel(model)
    })
    this.animate()
  },
  methods: {
    loadModel (filename) {
      loader.load(
        `/models/${filename}`,
        // Called when loaded
        (gltf) => {
          console.log(gltf)
          scene.add(gltf.scene)
        },
        // Called while load is in progress
        (xhr) => {
          this.percentLoaded = xhr.loaded / xhr.total * 100
          console.log((xhr.loaded / xhr.total * 100) + '% loaded')
        },
        // Called on loading errors
        (error) => {
          console.log(error)
        }
      )
    },
    animate () {
      requestAnimationFrame(this.animate)
      controls.update()
      renderer.render(scene, camera)
    }
  }
}
</script>

<style lang="scss">
.env-container {
  text-align: center;
  canvas {
    margin: 0 auto;
    border-bottom: solid 1px white;
    border-left: solid 1px white;
  }
}
</style>
